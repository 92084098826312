const portalConfig = {
  companyName: "Carthage Crabs",
  longName: "Portail de Carthage Crabs",
  loginDescription: "",
  allowLBUN: false, // Allow login by Username
  showCompanyNameInSidebar: false, // if to show company name next to the logo
  colors: {
    main: "#2190cf",
    gradiant: "#00AED9",
  },
  multilang: true,
  defaultLanguage: "fr-CA",
  languages: [
    {
      id: "fr-CA",
      title: "Français (CA)",
      shortName: "FR",
      flag: "ca",
    },
    {
      id: "en-CA",
      title: "English (CA)",
      shortName: "EN",
      flag: "ca",
    },
    {
      id: "fr-FR",
      title: "Français (FR)",
      shortName: "FR",
      flag: "fr",
    },
  ],
};

export default portalConfig;

const main = [
  {
    id: "applications",
    title: "Exiry",
    translate: "portal",
    type: "group",
    icon: "apps",
    auth: "login",
    children: [
      {
        id: "dashboard",
        title: "Tableau de bord",
        translate: "dashboard",
        type: "item",
        icon: "dashboard",
        url: "/app/dashboard",
        auth: "login",
      },
      {
        id: "Batch",
        title: "Lot",
        translate: "batch",
        type: "item",
        icon: "bookmarks",
        url: "/app/batch/list",
        auth: "batches",
      },
      // {
      //   id: "Review",
      //   title: "Review",
      //   translate: "review",
      //   type: "item",
      //   icon: "bookmarks",
      //   url: "/app/review/list",
      //   auth: "reviews",
      // },
    ],
  },
];

const user = [
  {
    id: "account",
    translate: "account",
    type: "group",
    auth: "login",
    children: [
      {
        id: "my_profile",
        translate: "my_profile",
        type: "item",
        auth: "login",
        icon: "account_circle",
        url: "/users/profile",
      },
    ],
  },
];

const help = [
  {
    id: "phone-assistance",
    translate: "phone_assistance",
    type: "item",
    auth: "login",
    icon: "phone",
    url: "/app/phone-assistance",
  },
];

export default main;

export { main, user, help };

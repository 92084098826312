import UserSystem from "./user-system";
import OptionsPageConfig from "./misc/option";
import { SeaFilesAdminConfig } from "./third-party/seafiles-admin/SeaFilesAdminConfig";
import CoreModules from "./core";
import ActivityLogModule from "./activityLog";
import AdminHome from "./home";

const modules = [
  ...UserSystem,
  ...CoreModules,
  // Misc
  OptionsPageConfig,
  // Third party
  SeaFilesAdminConfig,
  ActivityLogModule,
  // Home !important to be last
  AdminHome,
];

export default modules;

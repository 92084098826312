import React from "react";

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Category page Module",
  routes: [
    {
      path: "/category/:id",
      component: React.lazy(() => import("./CategoryPage")),
    },
  ],
};

export default config;

import React from "react";
import i18next from "i18next";

import en_US from "./i18n/en_US";

i18next.addResourceBundle("en-US", "dashboard", en_US);

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Tableau de board",
  auth: "login",
  routes: [
    {
      path: "/app/dashboard",
      component: React.lazy(() => import("./Dashboard")),
    },
  ],
};

export default config;

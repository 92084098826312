import { ApolloClient, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import jwtService from "./jwtService";

// Set Exiry backend server url
const uri = process.env.REACT_APP_GRAPHQL_URL;

// Set the Apollo Server http link
const httpLink = createUploadLink({ uri });

const middleWares = getMiddleware();

// Create the Apollo Client
const client = new ApolloClient({
  link: from([...middleWares, httpLink]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

function getMiddleware() {
  const authMiddleware = setContext((_, props) => {
    let { headers } = props;
    const authHeaders = jwtService.getAuthHeaders();
    if (authHeaders) {
      headers = { headers, ...authHeaders };
    }
    return { headers };
  });

  const errorMiddleware = onError(({ response, graphQLErrors, networkError }) => {
    // If the error comes from the graphQL
    if (Array.isArray(graphQLErrors)) {
      const grantError = graphQLErrors.find((error) => {
        return error.extensions.code.toUpperCase() === "GRANT_ERROR";
      });
      if (grantError) {
        jwtService.emit("onAutoLogout", grantError.message);
        jwtService.setSession(null);
        // client?.resetStore();
        response.errors = null;
      }
    }
    if (networkError) {
      console.error(networkError);
    }
  });

  return [authMiddleware, errorMiddleware];
}

export default client;

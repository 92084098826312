import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_FR from "./i18n/fr_FR";

i18next.addResourceBundle("fr-FR", "users", fr_FR);

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Gestion des utilisateurs",
  auth: "users",
  routes: [
    {
      path: "/admin/users/management/item/:itemId",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/admin/users/management/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/admin/users/management",
      component: () => <Redirect to="/admin/users/management/list" />,
    },
    {
      path: "/admin/users",
      component: () => <Redirect to="/admin/users/management/list" />,
    },
  ],
};

export default config;

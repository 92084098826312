import { gql } from "@apollo/client";

const UserLoginFields = gql`
  fragment UserLoginFields on User {
    id
    email
    firstName
    lastName
    profileImage
    role {
      id
      name
      privileges {
        nodes {
          id
          ... on Privilege {
            access {
              name
              slug
            }
            canView
            canViewOwn
            canEdit
            canCreate
            canDelete
          }
        }
      }
    }
  }
`;

export const loginDataFields = gql`
  ${UserLoginFields}
  fragment LoginDataFields on LoginData {
    token
    user {
      ...UserLoginFields
    }
  }
`;

export default UserLoginFields;

import React from "react";
import i18next from "i18next";

import fr_FR from "./i18n/fr_FR";

i18next.addResourceBundle("fr-FR", "access", fr_FR);

const config = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: "permissions",
  routes: [
    {
      path: "/admin/user-management/accesses/:id?-?:slug",
      component: React.lazy(() => import("./views/List.jsx")),
    },
    {
      path: "/admin/user-management/accesses/:id?",
      component: React.lazy(() => import("./views/List.jsx")),
    },
  ],
};

export default config;

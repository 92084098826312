import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US from "./app/i18n/en_US/main.json";
import businessLayer_en_US from "./app/i18n/en_US/business-layer.json";
import navigation_en_US from "./app/i18n/en_US/navigation.json";
import calendar_en_US from "./app/i18n/en_US/calendar.json";

const resources = {
  "en-US": {
    translation: en_US,
    bussLayer: businessLayer_en_US,
    navigation: navigation_en_US,
    calendar: calendar_en_US,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources,
    lng: "en-US",
    fallbackLng: "en-US",
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

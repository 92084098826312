import React from "react";
import { default as i18next } from "i18next";

import fr_FR from "./i18n/fr_FR";

i18next.addResourceBundle("fr-FR", "optApp", fr_FR);

const OptionsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: "config",
  routes: [
    {
      path: "/admin/misc/options",
      component: React.lazy(() => import("./OptionsPage")),
    },
  ],
};

export default OptionsPageConfig;

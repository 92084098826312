import React from "react";
import { default as i18next } from "i18next";

import fr_FR from "./i18n/fr_FR";

i18next.addResourceBundle("fr-FR", "activity_log", fr_FR);

const config = {
  settings: {},
  name: "activity_log",
  auth: "login",
  routes: [
    {
      path: "/app/activity_log",
      component: React.lazy(() => import("./views/List")),
    },
  ],
};

export default config;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate } from "@fuse";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Typography, Card, CardContent } from "@material-ui/core";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #009CBC, #10CCB3);",
    color: theme.palette.primary.contrastText,
  },
}));

function ResetPasswordPage(props) {
  const classes = useStyles();
  const { t } = useTranslation("resetPasswordApp");

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto shrink-0 items-center justify-center p-32"
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <FuseAnimate animation="transition.expandIn">
          <Card className="w-full max-w-384">
            <CardContent className="flex flex-col items-center justify-center p-32">
              <div className="w-128 m-32">
                <img src="assets/images/logos/logo-exiry.png" alt="Logo" />
              </div>

              <Typography variant="h6" className="mt-16 mb-32 uppercase">
                {t("reset_your_password")}
              </Typography>

              <ResetPasswordForm {...props} />

              <div className="flex flex-col items-center justify-center pt-32 pb-24">
                <Link className="font-medium" to="/login">
                  {t("return_to_login")}
                </Link>
              </div>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default ResetPasswordPage;

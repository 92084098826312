import React from "react";
import { default as i18next } from "i18next";

import en_US from "./i18n/en_US.json";

i18next.addResourceBundle("en-US", "batch", en_US);

const config = {
  settings: {},
  name: "batch",
  auth: "batches",
  routes: [
    {
      path: "/app/batch/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/batch/item/:id",
      component: React.lazy(() => import("./views/Item")),
    },
  ],
};

export default config;

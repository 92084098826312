import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";
import clsx from "clsx";
import JWTLoginTab from "./tabs/JWTLoginTab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #2190cf, #00C9CC);",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: theme.palette.primary.contrastText,
  },
  pictogram: {
    maxWidth: "700px",
    margin: "50px 0px",
  },
}));

function Login() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, "flex flex-col flex-1 shrink-0 p-24 md:flex-row md:p-0")}>
      <div className="hidden md:flex flex-col grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:shrink-0 md:flex-1 md:text-left">
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <h3 className="text-4xl text-white mb-8">Exiry - Portail de gestion</h3>
        </FuseAnimate>

        <FuseAnimate delay={400}>
          <>
            <p className="max-w-sm">
              This App is made using Exiry, a home-made framework from Tekru. Thanks to Exiry, we
              are happy to deliver faster and more secure enterprise applications (like intranet or
              extranet portals) with specific, interoperable and customizable modules.
            </p>
          </>
        </FuseAnimate>
      </div>

      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <img className="w-200 mb-32" src="/assets/logos/logo-exiry.png" alt="Logo" />
            <Typography variant="h6" className="text-center uppercase md:w-full mb-48">
              Login
            </Typography>

            <JWTLoginTab />

            <div className="flex flex-col items-center justify-center pt-32">
              <Link
                className="font-medium mt-8"
                id="forgot-password-link"
                to="/auth/forgot-password"
              >
                Forget password ?
              </Link>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Login;
//<span className="font-medium">Je n'ai pas de compte?</span>
//<Link className="font-medium" to="/auth/register">Créer un compte</Link>

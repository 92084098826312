import FuseUtils from "@fuse/FuseUtils";

function adminMenuHelper() {
  const data = [];
  const row_data = [
    {
      id: "snt-modules",
      title: "Modules Smart NewTech",
      elements: [
        {
          id: "dashboard",
          title: "Tableau de bord",
          auth: "platform-admin",
          icon: "dashboard",
          url: "",
        },
      ],
    },
    {
      id: "third-party-modules-modules",
      title: "Modules tiers",
      elements: [
        {
          id: "admin-third-party-modules-news-block",
          title: "Bloc de nouvelles",
          auth: "content",
          icon: "bookmarks",
          url: "/news-block/admin",
        },
        {
          id: "admin-third-party-modules-activity-logs",
          title: "Journal d'activité",
          auth: "activity-log",
          icon: "bookmarks",
          url: "/app/activity_log",
        },
      ],
    },
    {
      id: "users-access",
      title: "Utilisateurs et accès",
      elements: [
        {
          id: "admin-users-access-users-manager",
          title: "Gestion des utilisateurs",
          auth: "users",
          icon: "supervised_user_circle",
          url: "/admin/users/list",
        },
        {
          id: "admin-users-access-accesses",
          title: "Gestion des droits utilisateurs",
          auth: "permissions",
          icon: "supervised_user_circle",
          url: "/admin/user-management/accesses/",
        },
      ],
    },
    {
      id: "general-settings",
      title: "Paramètres généraux",
      elements: [
        {
          id: "admin-general-settings-dynamic-menu",
          title: "Menu latéral principal",
          auth: "platform-admin",
          icon: "",
          url: "/admin/misc/dynamic-menu",
        },
        {
          id: "admin-general-settings-notifications",
          title: "Paramètres des notifications",
          auth: "platform-admin",
          icon: "",
          url: "",
        },
        {
          id: "admin-general-settings-options",
          title: "Configuration",
          auth: "config",
          icon: "settings",
          url: "/admin/misc/options",
        },
      ],
    },
  ];

  // Verify for the Permission
  for (let category of row_data) {
    if (category.elements && category.elements.length > 0) {
      let tmp = [];
      for (let item of category.elements) {
        if (FuseUtils.hasPermission(item.auth)) {
          tmp.push(item);
        }
      }
      if (tmp.length > 0) {
        category.elements = tmp;
        data.push(category);
      }
    }
  }

  return data;
}

export default adminMenuHelper;

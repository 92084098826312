import LoginConfig from "app/main/auth/login";
import { RegisterConfig } from "app/main/auth/register/RegisterConfig";
import ForgotPasswordPageConfig from "app/main/auth/forgot-password";
import { ResetPasswordPageConfig } from "app/main/auth/reset-password";

export const AuthConfigs = [
  LoginConfig,
  RegisterConfig,
  ForgotPasswordPageConfig,
  ResetPasswordPageConfig,
];
